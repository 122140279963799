import { lastDayOfMonth,format } from 'date-fns'

export async function fetchAccounts() {
    const access_token = process.env.REACT_APP_TOKEN;
  
    const result =  await fetch(`https://id.getharvest.com/api/v2/accounts?access_token=${access_token}`);
    const accounts = await result.json();
    return accounts.accounts;
}

export async function fetchProjectsWithTimeEntries( accountId, period) {
    const firstDate = new Date(period.year, period.month, 1);
    const from = format(firstDate, 'yyyy-MM-dd');
    const to = format(lastDayOfMonth(firstDate), 'yyyy-MM-dd');

    const access_token = process.env.REACT_APP_TOKEN;
    let result =  await fetch(`https://api.harvestapp.com/v2/time_entries?access_token=${access_token}&account_id=${accountId}&from=${from}&to=${to}`);
    let enteries = await result.json();
    return enteries;
}

export async function fetchProjectsWithSubSupliers( accountId) {
    const access_token = process.env.REACT_APP_TOKEN;
    const result =  await fetch(`https://api.harvestapp.com/v2/projects?access_token=${access_token}&account_id=${accountId}&is_active=true`);
    const  entries = await result.json();
    const projectWithSubSupliers = entries.projects.filter((entry) => {
        if (entry.code) return entry.code.replace(/\s/g, "").toUpperCase().slice(-2) === "-S";
        else
         return entry.code
         ;
    })
    return projectWithSubSupliers;
}

