const nf = new Intl.NumberFormat("no-BM", { minimumFractionDigits: 1 });
export function formate(num) {
  return nf.format(num);
}

const cf = new Intl.NumberFormat("no-BM", { minimumFractionDigits: 2 , maximumFractionDigits: 2});
export function currencyFormate(num) {
  return cf.format(num);
}


const noFracton = new Intl.NumberFormat("no-BM", { maximumFractionDigits: 0 });
export function formatNoFraction(num) {
  return noFracton.format(num);
}


const noGroup = new Intl.NumberFormat("no-BM", {useGrouping: false});
export function formatNoGroup(num) {
  return noGroup.format(num);
}

const shortDate = new Intl.DateTimeFormat("no-BM", {dateStyle: 'short'});
export function formatShortDate(date) {
  return shortDate.format(date);
}