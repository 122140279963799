import { StyleSheet} from "@react-pdf/renderer";

    // Create styles
    const sharedStyles = StyleSheet.create({
        page: {
            backgroundColor: '#F4f1E7'
        },
        section: {
            margin: 10
        },
        h1: {
            fontSize: 20,
        },
        dt: {
            width:70,
        },
        document: {
            fontSize: 10
        },
        row: {
            flexDirection:'row',
            border: "solid 1 green"
        },
        hours_cell: {
            width: 23,
            textAlign: "right",
            padding: 2,
            fontSize: 7
        },
        name_cell: {
            fontSize: 9,
            padding: 2,
            width: 120
        },
        sum_cell: {            
            width: 30,
            borderTop: 'solid 1 #999999'
        },
        amount_cell: {            
            width: '95%',
            textAlign: "right",
            margin:"5mm"
        },
        heading: {
            backgroundColor: '#352F6C',
            color: '#FFFFFF'
        },
        weekend: {
            color: '#E61A6B'
        },
        footer: {
            backgroundColor: '#03DAC6'
        },
        title: {
            width: '50%'
        },
        logo: {
            width: '50%',
            alignItems: 'flex-end'
        },
        logo_image: {
            width: '40%'
        }
        
        
    });


    export default sharedStyles;

