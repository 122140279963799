import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import {  format, addDays, getDaysInMonth, isWeekend } from 'date-fns'
import sharedStyles from "./PdfStyles";
import PdfUser from "./PdfUser";
import { nb } from 'date-fns/locale'
import { currencyFormate, formate } from "./utiil/Formate";

const PdfInvoice = ({project}) => {

    const firstDate = new Date(project.period.year, project.period.month, 1);
    const lastDay = getDaysInMonth(firstDate);

    const users = Object.values(project.users);
    const userList = users.map((user, index) => {
        return <PdfUser key={index} user={user} lastDay={lastDay} />
    })

    const daysList = [];
    const sums = [];
    for (let index = 1; index <= lastDay; index++){
        const weeked = isWeekend(addDays(firstDate, index-1))

        if (weeked){
            daysList.push(<Text key={index}style={{...sharedStyles.hours_cell, ...sharedStyles.heading, ...sharedStyles.weekend}} >{index}</Text>);
        } else {
            daysList.push(<Text key={index} style={{...sharedStyles.hours_cell, ...sharedStyles.heading}} >{index}</Text>);
        }

        if(project.entries[index]){
            sums.push(formate(project.entries[index]));
        } else {
            sums.push("-");
        }   
    }    

    const sumList = sums.map((entery, index) => {
        return(<Text key={index} style={sharedStyles.hours_cell}>{entery}</Text>)
    })

    const periodName = format( firstDate, 'MMMM yyyy', {locale: nb}).replace(/./,c => c.toUpperCase());
    return (
    <Document style={sharedStyles.document}>
      <Page orientation="landscape" size="A4" style={sharedStyles.page}>
        <View style={sharedStyles.section}>
            <View style={sharedStyles.row}>
                <View style={sharedStyles.title}>
                    <Text style={sharedStyles.h1}>Timerapport</Text>
                    <View> 
                        <View style={{...sharedStyles.row}}>
                            <Text style={sharedStyles.dt}>Kunde: </Text><Text style={sharedStyles.dd}>{project.client.name}</Text>
                        </View >
                        <View style={{...sharedStyles.row}}>
                            <Text style={sharedStyles.dt}>Prosjekt:</Text><Text style={sharedStyles.dd}>{project.name}</Text>
                        </View >
                        <View style={{...sharedStyles.row}}>
                            <Text style={sharedStyles.dt}>Periode:</Text><Text style={sharedStyles.dd}>{periodName}</Text>
                        </View >
                    </View>
                </View>
                <View style={sharedStyles.logo}>
                    <Image style={{...sharedStyles.logo_image}} src="/variant-logo.png" cache={false} />
                </View>
            </View>
        </View>
        <View style={sharedStyles.section}>
            <View style={{...sharedStyles.row, ...sharedStyles.heading}}>
                <Text style={{...sharedStyles.name_cell}}>Navn</Text>
                {daysList}
                <Text style={{...sharedStyles.hours_cell, ...sharedStyles.heading, ...sharedStyles.sum_cell}}>Sum</Text>
            </View>
            {userList}
            <View style={{...sharedStyles.row, ...sharedStyles.footer}}>
                <Text style={{...sharedStyles.name_cell}}>Sum</Text>
                {sumList}
                <Text style={{...sharedStyles.hours_cell, ...sharedStyles.sum_cell}}>{formate(project.sum_hours)}</Text>
            </View>
            <View>
                <Text style={sharedStyles.amount_cell}>Totalt beløp: {currencyFormate(project.sum_amount)}</Text>
            </View>
        </View>
      </Page>
    </Document>
    )
};

export default PdfInvoice;
