import Project from "./Project";

const Projects = ({ projects }) => {
  
  const projectList = Object.values(projects).sort((a,b)=> {
    if (a.code > b.code) return 1;
    return -1;
  })
  .map((project) => {
    return <Project key={project.id} project={project} />;
  });

  return (
    <ul>{projectList}</ul>
  );
};
export default Projects;
