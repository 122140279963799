import ProjectDetails from "./ProjectDetails";
import { useState } from "react";


const Project = ({project}) => {
    const [showDetails, setShowDetails] = useState(false)

    const handleShowDetails = async () => {
        setShowDetails(!showDetails);
    }

    return (
        <li>
            <button className="project-button" onClick={handleShowDetails}>
                {project.code} -&nbsp;
                {project.client.name} -&nbsp; 
                {project.name}
            </button>
            <ProjectDetails show={showDetails} onClose={handleShowDetails} project={project} />
        </li>
    )
}
export default Project;