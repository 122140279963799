const Download = ({ handleOnClick, downloading }) => {
  return (
    <>
      {downloading && <span>Laster ned ...</span>}

      {!downloading && (
        <div>
          <button onClick={handleOnClick}>Last ned alle underlag</button>
        </div>
      )}
    </>
  );
};
export default Download;
